




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import date from 'quasar/src/utils/date.js';;
import ability from '../ability';

@Component({})
export default class MemberMe extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;

  private rules: any = [];

  public async created() {
    const rules = ability.rules.slice();
    rules.sort();
    this.rules = rules;
  }

}
